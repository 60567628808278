import axios from 'axios'
import { message } from 'antd';
import qs from 'qs'

const instance = axios.create({
    // baseURL: "http://47.103.91.252/ZhiXiaoZanApp/",
    baseURL: "/ArBoxApp/",
    // baseURL:'/api',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
})
// 请求拦截器
instance.interceptors.request.use(function(config) {
    
    let only_code = localStorage.getItem('only_code')
    if (config.method === 'get' && !config.params['only_code'] && only_code) {
        config.params['only_code'] = only_code
    }else if(config.method === 'post' && !config.data) {
        // console.log(1);
        config.data = { only_code }
    }else if(config.method === 'post' && config.data) {
        // console.log(2);
        // console.log(config);
        config.data = Object.assign( {only_code}, config.data )
    }
    config.data = qs.stringify(config.data)
    return config
}, function(error) {
    return Promise.reject(error)
})

// 响应拦截器
instance.interceptors.response.use(function (response) {
    // console.log(response);
    if (response.data.info) {
        return response.data.geocodes[0]
    }
    if (response.config.url === "caro/figure" && response.data.code === '999') {
        return Promise.reject(response.data)
    }
    if (response.data.code === '000' || response.data.code === '0') {
        return response.data
    }else {
        message.error(response.data.data);
        return Promise.reject(response.data)
    }
}, function(error) {
    return Promise.reject(error)
})

export default instance

