import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import './index.scss'
import { editPsd } from '../../api/api'
// 字体图标
import {
    createFromIconfontCN, UserOutlined, MenuUnfoldOutlined, LogoutOutlined,
    MenuFoldOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Avatar, Button, Modal, Input, message } from 'antd';
const { Header, Sider, Content, Footer } = Layout;



const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_2801457_weui54w46va.js',
});

function Top(props) {
    const navigate = useNavigate() // 跳转
    const [userInfo, setUserInfo] = useState();
    const [avaterInfo, setAvaterInfo] = useState('none')
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [oldPwd, setOldPwd] = useState();
    const [newPwd, setNewPwd] = useState();

    useEffect(() => {
        const info = JSON.parse(localStorage.getItem('userInfo'))
        setUserInfo(info)
        console.log(userInfo);
    }, [])
    // 经过头像
    const avatarEnter = () => {
        setAvaterInfo('block')
    }
    //  离开头像
    const avatarLeave = () => {
        setAvaterInfo('none')
    }
    // 退出登录
    const logout = () => {
        localStorage.clear()
        navigate('/login')
    }
    // 修改密码
    const showModal = () => {
        setIsModalVisible(true);
    }
    const handleOk = () => {
        editPsd({ old_psd: oldPwd, new_psd: newPwd, user_id: localStorage.getItem('id') }).then(res => {
            message.success(res.data)
            setIsModalVisible(false);
            setOldPwd()
            setNewPwd()
        })

    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setOldPwd()
        setNewPwd()
    };
    const changeInput = (e, name) => {
        console.log(e);
        console.log(name);
        switch (name) {
            case 'oldPwd':
                setOldPwd(e.target.value)
                break
            case 'newPwd':
                setNewPwd(e.target.value)
                break
        }
    }

    return (
        <Header className='header'>
            <div className="header_fl">
                <IconFont type="icon-xitongguanli" /><div className='title'>TMS管理系统</div>
                {/* <span style={{ margin: '0 15px 0 0' }}>{userInfo ? userInfo.username : ''}</span> */}
                
                <div style={{ margin: '0 0 0 50px', fontSize: '15px' }}>{userInfo ? userInfo.shop_name : ''}</div>
            </div>

            <div className='header_fr'>

                {/* // userInfo.avater ?
                    //   <div onMouseEnter={avatarEnter} onMouseLeave={avatarLeave} className='avater'>
                    //     <Avatar src={userInfo.avater} />
                    //     <div className='avaterInfo' style={{ display: avaterInfo }}>
                    //       <div><IconFont type="icon-xiugaimima" /> 修改密码</div>
                    //       <div onClick={logout}><LogoutOutlined /> 退出</div>
                    //     </div>
                    //   </div>
                    //   : */}
                <div onMouseEnter={avatarEnter} onMouseLeave={avatarLeave} className='avater'>
                    {console.log(userInfo)}
                    <span style={{ margin: '0 15px 0 0' }}>{userInfo ? userInfo.username : ''}</span>
                    <Avatar icon={<UserOutlined />} />
                    <div className='avaterInfo' style={{ display: avaterInfo }}>
                        <div onClick={showModal}><IconFont type="icon-xiugaimima" /> 修改密码</div>
                        <div onClick={logout}><LogoutOutlined /> 退出</div>
                    </div>
                </div>
                <Modal title="修改密码" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                    <Input style={{ margin: '0 0 15px 0' }} placeholder='请输入原密码' onChange={(e) => changeInput(e, 'oldPwd')} value={oldPwd} />
                    <Input placeholder='请输入新密码' onChange={(e) => changeInput(e, 'newPwd')} value={newPwd} />
                </Modal>

            </div>
        </Header>
    );
}

export default Top;