
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Layout, Menu, Button } from 'antd';
// 字体图标
import {
    createFromIconfontCN, MenuUnfoldOutlined,
    MenuFoldOutlined,
} from '@ant-design/icons';
import './index.scss';
import { getRoleUrls } from '../../api/api'
const { Sider } = Layout;
const { SubMenu } = Menu;

const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_3210007_82974q48dl.js',
});

// 菜单栏列表
const navArr = [
    {
        key: '/',
        icon: 'icon-shouye',
        title: '首页',
    },
    {
        key: '/role',
        icon: 'icon-jiaoseguanli',
        title: '角色相关',
        children: [
            { key: '/role/often', icon: 'icon-yonghuliebiao', title: '常用客户' },
            { key: '/role/qiye_admin', icon: 'icon-yonghuliebiao', title: '企业用户管理' },
        ]
    },
    {
        key: '/waybill',
        icon: 'icon-yundanguanli',
        title: '运单管理',
        children: [
            { key: '/waybill/waybill_about', icon: 'icon-yonghuliebiao', title: '运单查询' },
            { key: '/waybill/abnormal_admin', icon: 'icon-yonghuliebiao', title: '异常管理' },
            { key: '/waybill/sign_admin', icon: 'icon-yonghuliebiao', title: '签收管理' },
            { key: '/waybill/ticket_making', icon: 'icon-yonghuliebiao', title: '制票（超级用户）' },
            { key: '/waybill/applyDel_list', icon: 'icon-yonghuliebiao', title: '申请删除运单列表' },
            { key: '/waybill/batch_departure', icon: 'icon-yonghuliebiao', title: '批量发车列表' },
        ]
    },
          
    {
        key: '/waybill',
        icon: 'icon-yundanguanli',
        title: '运单管理',
        children: [
            { key: '/waybill/transfer_list', title: '中转列表' },
        ]
    },
    {
        key: '/driver',
        icon: 'icon-siji',
        title: '司机相关',
        children: [
            { key: '/driver/driver_list', title: '司机车牌号统计列表' },
        ]
    },
    {
        key: '/settled',
        icon: 'icon-shanghupiliangruzhu',
        title: '入驻相关',
        children: [
            { key: '/settled/settled_list', icon: 'icon-yonghuliebiao', title: '申请入驻列表' },
            { key: '/settled/statistics_list', icon: 'icon-yonghuliebiao', title: '公司统计列表' },
        ]
    },
    {
        key: '/contract',
        icon: 'icon-hetong',
        title: '合同相关',
        children: [
            { key: '/contract/contract_admin', icon: 'icon-yonghuliebiao', title: '合同管理' },
        ]
    },
    {
        key: '/announcement',
        icon: 'icon-gonggao',
        title: '公告相关',
        children: [
            { key: '/announcement/announcement_admin', icon: 'icon-yonghuliebiao', title: '公告管理' },
        ]
    },
    {
        key: '/fenxi',
        icon: 'icon-fenxi',
        title: '分析相关',
        children: [
            { key: '/fenxi/statistics', icon: 'icon-yonghuliebiao', title: '财务分析' },
            { key: '/fenxi/abnormal', title: '异常分析' },
            { key: '/fenxi/map', icon: 'icon-yonghuliebiao', title: '中国地图' },
        ]
    },
    {
        key: '/sorting',
        icon: 'icon-fenxi',
        title: '排序相关',
        children: [
            { key: '/sorting/sorting_list', title: '排序列表' },
        ]
    },
    {
        key: '/money',
        img_code: 'icon-caiwu',
        cite: '财务相关',
        second: [
            { url: '/money/supplier', name: '付款申请' },
            { url: '/money/supplier2', name: '客户对账' },
            { url: '/money/supplier_list', name: '付款申请列表' },
            { key: '/money/checkList', title: '对账列表' },
        ]
    },
    {
        key: '/company',
        img_code: 'icon-gongsi',
        cite: '公司管理',
        second: [
            { url: '/company/company_list', name: '企业商铺列表' },
            { url: '/company/apply_list', name: '绑定申请列表' },
            { url: '/company/bound_list', name: '已绑定公司列表' },
        ]
    },
    {
        key: '/invest',
        img_code: 'icon-chongzhi',
        cite: '充值相关',
        second: [
            { url: '/invest/money', name: '充值金额设置' },
            { url: '/invest/num', name: '充值条数' },
            { url: '/invest/list', name: '充值点单列表' },
        ]
    },
    {
        key: '/area',
        img_code: 'icon-quyupaixu',
        cite: '区域金额相关',
        second: [
            { url: '/area/money', name: '区域金额管理' },
        ]
    }
]

function Left() {
    const navigate = useNavigate() // 跳转
    const path = useLocation().pathname.split('/')
    let selectKey  // 当前选中菜单
    let defaultOpenKeys
    // const [selectKey, setSelectKey] = useState(selectKey2);
    const [collapsed, setCollapsed] = useState(localStorage.getItem('collapsed') && true) // 控制菜单栏收缩
    const [navList, setNavList] = useState([]) // 菜单栏
    console.log(path);
    if (path.length === 2) {
        defaultOpenKeys = ['/' + path[1]]
        selectKey = '/'
    } else {
        defaultOpenKeys = ['/' + path[1]]
        selectKey = '/' + path[1] + '/' + path[2]
    }
    const [openKeys, setOpenKeys] = useState(defaultOpenKeys);
    if (openKeys[0] !== defaultOpenKeys[0] && !localStorage.getItem('url')) {
        setOpenKeys(defaultOpenKeys)
    } else {
        setTimeout(() => {
            localStorage.removeItem('url')
        }, 0)
    }
    // 菜单栏收缩
    const toggleCollapsed = () => {
        setCollapsed(!collapsed)
    }
    // 二级菜单
    let rootSubmenuKeys = []
    navList.map(item => {
        rootSubmenuKeys.push(item.url)
    })
    // const rootSubmenuKeys = ['/role', '/waybill']
    useEffect(() => {
        list()
    }, [])
    useEffect(() => {
        collapsed ?
            localStorage.setItem('collapsed', true)
            :
            localStorage.removeItem('collapsed')
    }, [collapsed])

    const list = () => {
        getRoleUrls().then(res => {
            // res.data[5].second.push({level: 2, img_code: {}, name: '对账列表', id: 100, url: '/money/checkList'})
            // res.data[0].second.push({level: 2, img_code: {}, name: '常用客户', id: 100, url: '/role/often'})
            // res.data[0].second.push({level: 2, img_code: {}, name: '企业用户管理', id: 100, url: '/role/qiye_admin'})
            setNavList(res.data)
        })
    }
    const onSelect = ({ item, key, keyPath, selectedKeys, domEvent }) => {
        // console.log(key);
        // setSelectKey(key)
    }
    const onOpenChange = keys => {
        console.log(keys);
        let latestOpenKey
        latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
        console.log(latestOpenKey);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
            localStorage.setItem('url', JSON.stringify(keys))
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
            localStorage.setItem('url', JSON.stringify(latestOpenKey ? [latestOpenKey] : []))
        }
    };

    return (
        <div style={{ height: '100%', position: 'relative', zIndex: '999',  }}>
            <Sider className={[collapsed ? '' : 'leftScroll', 'slider']} collapsedWidth={48} collapsed={collapsed} style={{ height: '100%' }}>
                <Menu
                    openKeys={collapsed ? undefined : openKeys} onOpenChange={onOpenChange} onSelect={onSelect}
                    selectedKeys={[selectKey]}
                    mode="inline"
                    theme="dark"
                    inlineCollapsed={collapsed}
                >
                    <Menu.Item key='/' icon={<IconFont type='icon-shouye' />} style={{ marginTop: '0', display: 'flex', alignItems: 'center' }}>
                        <Link to='/'>首页</Link>
                    </Menu.Item>
                    {
                        navList.map(item => (
                            item.second ?
                                <SubMenu key={item.url} icon={<IconFont type={item.img_code} />} title={item.cite}>
                                    {
                                        item.second.map(item2 => (
                                            // icon={<IconFont type={item2.icon} />}
                                            <Menu.Item key={item2.url} style={{ marginTop: '0', display: 'flex', alignItems: 'center' }}>
                                                <Link to={item2.url}>{item2.name}</Link>
                                            </Menu.Item>
                                        ))
                                    }
                                </SubMenu>
                                :
                                <Menu.Item key={item.url} icon={<IconFont type={item.img_code} />} style={{ marginTop: '0', display: 'flex', alignItems: 'center' }}>
                                    <Link to={item.url}>{item.cite}</Link>
                                </Menu.Item>
                        ))
                    }
                </Menu>

            </Sider>
            <Button type="primary" onClick={toggleCollapsed} className={collapsed ? 'slider_btn2' : 'slider_btn'} icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />} >
            </Button>
        </div>
    );
}

export default Left;
