import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Routes, Route, } from 'react-router-dom'
import { Spin } from 'antd'
import App from '../App'
import Login from '../pages/Login'
import Www from '../pages/Www'


const router_arr = [
    {
        path: '/', component: App, children: [
            { path: '/', component: lazy(() => import("../pages/Home")) },
        ]
    },
    // { path: '/login', component: Login },
    // { path: '/settled', component: lazy(() => import("../pages/Login/Settled")) },
    { path: '*', component: Www },
]

const Loading = () => {
    return (
        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
            <Spin size="large" />
        </div>
    )
}
const MyRouter = () => (
    <BrowserRouter>
        <Routes>
            {
                router_arr.map((item, index) => {
                    return (
                        item.children ?
                            <Route key={index} path={item.path} element={<item.component />}>
                                {/* <Redirect from="/" to="/home" /> */}
                                {
                                    item.children.map((item2, index2) => <Route key={index2} path={item2.path} element={
                                        <Suspense fallback={<Loading />}>
                                            <item2.component />
                                        </Suspense>
                                    }></Route>)
                                }
                            </Route>

                            :
                            <Route key={index} path={item.path} element={
                                <Suspense fallback={<Loading />}>
                                    <item.component /></Suspense>}></Route>
                    )
                })
            }
        </Routes>

    </BrowserRouter>
)

export default MyRouter