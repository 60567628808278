
import React, { useEffect, useState } from 'react';
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import { Layout } from 'antd';

import Top from './component/Top'
import Left from './component/Left'


const { Content, Footer } = Layout;



function App() {
  const navigate = useNavigate() // 跳转
  let re = new RegExp("\s?" + "isLogin" + "=([^;]+)(;|$)")
  useEffect(() => {
  }, [])
  return (
    <Outlet />
    // <Layout style={{ height: '100vh', minWidth: '1000px' }}>
    //   <Top />
    //   <Layout>
    //     <Left />
    //     <Content style={{ overflow: 'hidden', overflowY: 'scroll', }}>
    //       <Outlet />
    //       {/* <Footer>Footer</Footer> */}
    //     </Content>
    //   </Layout>
    // </Layout>
  );
}

export default App;
